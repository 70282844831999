<template>
  <div class="cover">
    <div class="container">
      <h1 v-html="title"></h1>
    </div>
  </div>
  
  <div v-if="pageId == 103">
    <Organizers :globalData="globalData" :pageId="pageId"></Organizers>
  </div>
  <div v-else-if="pageId == 74">
    <Results :globalData="globalData"></Results>
  </div>
  <div v-else-if="pageId == 75" class="container">
    <Participants :globalData="globalData" :pageContent="mainContent"></Participants>
  </div>
  <div v-else id="content">
    <div class="container" v-html="mainContent">
    </div>
  </div>
  
</template>

<script>
import Participants from './Participants.vue'
import Results from './Results.vue'
import Organizers from './Organizers.vue'

export default {
  name: 'SubPage',
  props:['globalData','pageId'],
  components: {
    Participants, Results, Organizers
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head
    let mainContent = contents.page.mainContent
    let title = contents.page.title
    if(isLocalhost===true && mainContent!==undefined){
      mainContent = mainContent.replaceAll('/app','')
      title = title.replaceAll('/app','')
    }
    if(this.pageId==70){
      mainContent = mainContent.replaceAll('{btn.registration}',contents.btn.registration)
    }


    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    return{
      mainContent,
      contents,
      language,
      title
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.mainContent = this.contents.page.mainContent
      this.title = this.contents.page.title
      if(isLocalhost===true && this.mainContent!==undefined){
        this.mainContent = this.mainContent.replaceAll('/app','')
      }
      if(this.pageId==70){
        this.mainContent = this.mainContent.replaceAll('{btn.registration}',this.contents.btn.registration)
      }

      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    language(newValue){
      this.contents = this.globalData.contents[newValue][this.pageId]
      this.title = this.contents.page.title
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  }
}
</script>
<style>
.cover{
    width: 100%;
    height: 300px;
    background-color: #5f6062;
    background-image: url('../assets/bg/subpage_header_left.png'),url('../assets/bg/subpage_header_right.png');
    background-repeat: no-repeat,no-repeat;
    background-size: auto,auto;
    background-position: left top, right top;
    margin: 100px 0 0 0;
    color: #fff;
}
.cover h1 {
    font-family: 'Proxima Nova Regular',sans-serif;
    font-size: 50px;
    line-height: 280px;
    font-weight: 800;
    text-align: center;
    text-transform: initial;
}

/*--------------------
        CONTENT
---------------------*/
#content{width:100%;}
#content .container{padding:60px 0 120px 0;}
#content h1{font-size:40px;line-height:40px;margin:0 0 50px 0;font-weight:300;}
#content h2{font-size:20px;line-height:24px;color: #FF6104; margin:35px 0 25px 0;font-weight:600;font-family: 'Proxima Nova Medium', sans-serif;}
#content h3{font-size:18px;line-height:20px;color: #000000; margin:35px 0 25px 0;font-weight:600;font-family: 'Proxima Nova Medium', sans-serif;}
#content a{color:#1e4164;font-weight:700;text-decoration:underline;}
#content p{margin:0 0 25px 0;line-height:22px;}
#content a .bi{color:#ff6104;font-size: 1.5rem;margin-right: 1.6rem}
#content a .bi-linkedin{margin-right:0}
#content ul{margin:0 0 30px 0;}
#content ul ul{margin:0 0 0 30px;}
#content ul li{line-height:25px;list-style-type:none;}
#content ul li:before{content:" - ";font-size:25px;color:#1e4164;}
#content .solve{position:relative;overflow:hidden;background:#c3c3c3;padding:10px 170px 10px 30px;margin:0 0 30px 0;border-left:5px solid #FF6C60;cursor:pointer;}
#content .solve p{margin-bottom:15px;}
#content .solve .teamname{position:absolute;bottom:10px;right:10px;font-size:12px;line-height:12px;color:#FF6C60;}
#content .solve.closed{height:60px;}
#content iframe{width:100% !important;}
@media (max-width: 1030px){
  #video{height:400px;}
  #video iframe{height:400px!important;}
}

@media (max-width: 768px){
  .cover{
    background-image: url('../assets/bg/subpage_header_left.png'),none;
    height: 160px;
  }
  .cover h1{font-size:40px;line-height:50px;padding:40px 0 0 0;}
  .cover .container{height:313px;}
}
@media (max-width:320px) {
    /*  VIDEO  */
    #video{height:200px;}
    #video iframe{height:200px!important;}
}
</style>
